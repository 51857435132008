import React from 'react'
import HomeContainer from '../containers/HomeContainer'

const Home = () => {
  
  return (
    <div className="home-component">
      <HomeContainer/>
    </div>
  ) 
}

export default Home;

